.added-padding {
  padding: 30px 0 30px 0;
}

.title-section {
  padding: 1% 0 3% 0;
}

.junglo-logo {
  width:250px;
  height: auto;
  pointer-events: none;
}

// .andy-div {
//   margin: 0 -20% 0 0;
// }

// .seungwoo-div {
//   margin: 0 -10% 0 0;
// }

// .moon-div {
//   margin: 0 0% 0 0;
// }

// .yoonho-div {
//   margin: 0 0 0 0%;
// }

.team-page {
  display: flex;
  flex-direction: column;
  padding: 3% 0 3% 0;
  background-color: #f5f5f5;
}

.team-title {
  padding-bottom: 3%;
}

.team-members {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 2% 0;
}

.team-members-middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0% 0;
}

.team-members-mobile {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
}

.team-rows {
  padding: 0 0 0 0;
}

.team-rows-mobile {
  padding: 0 5% 5% 5%;
}

.name-text {
  font-size: 18px;
  font-weight: 500;
}

.title-text {
  font-size: 14px;
}

.individual-member {
  border-radius: 50%;
}

.about-junglo-text {
  align-items: center;
}

.noclick-image {
  pointer-events: none;
}

.carousel-container {
  margin: 5% 0 9% 0;
}

.carousel-application {
  width: 40%;
  height: auto;
  pointer-events: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 37px;
  width: 37px;
  outline: gray;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid gray;
  background-image: none;
}

.carousel-control-next-icon {
  margin-right: -30%;
}

.carousel-control-prev-icon {
  margin-left: -30%;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 23px;
  color: gray;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 23px;
  color: gray;
}

.app-info-info {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 5% 0 0 0;
}

.app-info-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 4% 0 2%
}

.info-inner-title {
  color: #8BCA45;
}

.info-inner-text {
  margin: -3% 0 0 0;
  }

.footer-section {
  padding: 10px 0 10px 0;
  background-color: #34282C;
  color: white;
}

.u-section-1 {
  background-image: none;
}

.u-section-1 .u-sheet-1 {
  min-height: 581px;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 581px;
}

.u-section-1 .u-container-layout-1 {
  padding: 40px 25px;
}

.u-section-1 .u-text-1 {
  letter-spacing: normal;
  font-family: Barlow;
  font-weight: 700;
  font-size: 3rem;
  text-transform: none;
  margin: 0;
}

.u-section-1 .u-text-2 {
  font-size: 1.125rem;
  margin: 30px 78px 0 0;
}

.u-section-1 .u-btn-1 {
  background-image: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: none;
  font-weight: 700;
  margin: 20px auto 0 0;
  padding: 10px 52px 10px 51px;
}

.u-section-1 .u-image-1 {
  min-height: 581px;
  background-image: url("images/e8048498e410d.gif");
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px 0 30px 40px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 377px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 479px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 0;
  }

  .u-section-1 .u-image-1 {
    min-height: 479px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 265px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-1 .u-image-1 {
    min-height: 367px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 498px;
  }

  .u-section-1 .u-container-layout-1 {
    // padding-left: 10px;
    // padding-right: 10px;
  }

  .u-section-1 .u-text-1 {
    font-size: 3.75rem;
  }

  .u-section-1 .u-image-1 {
    min-height: 500px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-left: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 313px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-1 .u-image-1 {
    min-height: 315px;
  }
}.u-section-2 {
  background-image: none;
}

.u-section-2 .u-sheet-1 {
  padding: 0 0 5% 0;
}

.u-section-2 .u-text-1 {
  font-weight: 700;
  background-image: none;
  width: 527px;
  margin: 60px auto 0 auto;
}

.u-section-2 .u-text-2 {
  margin: 20px 235px 0;
}

.u-section-2 .u-list-1 {
  min-height: 526px;
  grid-template-rows: auto auto;
  width: 570px;
  margin: 45px 0 0 auto;
}

.u-section-2 .u-repeater-1 {
  grid-auto-columns: calc(50% - 10px);
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px 20px;
  grid-auto-rows: calc(50% - 10px);
}

.u-section-2 .u-list-item-1 {
  background-image: none;
}

.u-section-2 .u-container-layout-1 {
  padding: 30px;
}

.u-section-2 .u-icon-1 {
  height: 48px;
  width: 48px;
  margin: 0 auto;
}

.u-section-2 .u-text-3 {
  margin: 20px 0 0;
}

.u-section-2 .u-list-item-2 {
  background-image: none;
}

.u-section-2 .u-container-layout-2 {
  padding: 30px;
}

.u-section-2 .u-icon-2 {
  height: 48px;
  width: 48px;
  margin: 0 auto;
}

.u-section-2 .u-text-4 {
  margin: 20px 0 0;
}

.u-section-2 .u-container-layout-3 {
  padding: 30px;
}

.u-section-2 .u-icon-3 {
  height: 48px;
  width: 48px;
  margin: 0 auto;
}

.u-section-2 .u-text-5 {
  margin: 20px 0 0;
}

.u-section-2 .u-list-item-4 {
  background-image: none;
}

.u-section-2 .u-container-layout-4 {
  padding: 30px;
}

.u-section-2 .u-icon-4 {
  height: 48px;
  width: 48px;
  margin: 0 auto;
}

.u-section-2 .u-text-6 {
  margin: 20px 0 0;
}

.u-section-2 .u-text-7 {
  margin: 30px 497px 60px;
}

.u-section-2 .u-btn-1 {
  border-style: none none solid;
  padding: 0;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 825px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 135px;
    margin-right: 135px;
  }

  .u-section-2 .u-list-1 {
    height: auto;
    width: 470px;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }

  .u-section-2 .u-text-7 {
    width: auto;
    margin: 27px 397px 45px;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1386px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .u-section-2 .u-list-1 {
    min-height: 503px;
    margin-top: 596px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  }

  .u-section-2 .u-text-7 {
    margin: 45px 287px 60px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1406px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-list-1 {
    margin-top: 591px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-text-7 {
    margin-left: 197px;
    margin-right: 197px;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1430px;
  }

  .u-section-2 .u-text-1 {
    width: 340px;
  }

  .u-section-2 .u-list-1 {
    min-height: 684px;
    margin-top: 462px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-repeater-1 {
    grid-auto-columns: 100%;
    grid-template-columns: 100%;
    grid-auto-rows: calc(25% - 15px);
  }

  .u-section-2 .u-text-7 {
    margin-left: 97px;
    margin-right: 97px;
  }
}.u-section-3 {
  background-image: none;
}

.u-section-3 .u-sheet-1 {
  min-height: 692px;
}

.u-section-3 .u-layout-wrap-1 {
  width: 790px;
  margin: 60px auto 0 0;
}

.u-section-3 .u-layout-cell-1 {
  min-height: 572px;
}

.u-section-3 .u-container-layout-1 {
  padding: 30px;
}

.u-section-3 .u-text-1 {
  font-size: 4.5rem;
  font-weight: 700;
  margin: 0;
}

.u-section-3 .u-image-1 {
  height: 413px;
  margin: 20px auto 0;
}

.u-section-3 .u-text-2 {
  font-size: 1.125rem;
  margin: 20px 362px 0 0;
}

.u-section-3 .u-btn-1 {
  border-style: none none solid;
  font-weight: 400;
  padding: 0;
}

.u-section-3 .u-list-1 {
  width: 350px;
  min-height: 572px;
  margin: -572px 0 60px auto;
}

.u-section-3 .u-repeater-1 {
  grid-auto-columns: 100%;
  grid-template-columns: 100%;
  grid-gap: 10px 10px;
  grid-auto-rows: calc(50% - 5px);
}

.u-section-3 .u-container-layout-2 {
  padding: 30px;
}

.u-section-3 .u-text-3 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 154px 0 0;
}

.u-section-3 .u-text-4 {
  margin: 20px 0 0 51px;
}

.u-section-3 .u-btn-2 {
  border-style: none none solid;
  margin-left: 51px;
  margin-right: 51px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 20px;
  padding: 0;
}

.u-section-3 .u-container-layout-3 {
  padding: 30px;
}

.u-section-3 .u-text-5 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 106px 0 0;
}

.u-section-3 .u-text-6 {
  margin: 20px 0 0 51px;
}

.u-section-3 .u-btn-3 {
  border-style: none none solid;
  margin-left: 51px;
  margin-right: 51px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 20px;
  padding: 0;
}

@media (max-width: 1199px) {
  .u-section-3 .u-sheet-1 {
    min-height: 710px;
  }

  .u-section-3 .u-layout-wrap-1 {
    width: 590px;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 590px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-1 {
    font-size: 3.75rem;
  }

  .u-section-3 .u-image-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-2 {
    margin-right: 162px;
  }

  .u-section-3 .u-list-1 {
    height: auto;
    margin-top: -590px;
    margin-bottom: 78px;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-sheet-1 {
    min-height: 974px;
  }

  .u-section-3 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 577px;
  }

  .u-section-3 .u-list-1 {
    width: 710px;
    min-height: 257px;
    margin: 20px auto 60px 0;
  }

  .u-section-3 .u-repeater-1 {
    grid-auto-columns: calc(50% - 5px);
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-auto-rows: 100%;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-1 {
    height: 325px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-2 {
    margin-right: 152px;
  }

  .u-section-3 .u-list-1 {
    width: 540px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-repeater-1 {
    grid-auto-columns: 100%;
    grid-template-columns: 100%;
    grid-auto-rows: calc(50% - 5px);
  }

  .u-section-3 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-3 {
    margin-right: 24px;
  }

  .u-section-3 .u-text-4 {
    margin-left: 0;
  }

  .u-section-3 .u-btn-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-3 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-5 {
    margin-right: 0;
  }

  .u-section-3 .u-text-6 {
    margin-left: 0;
  }

  .u-section-3 .u-btn-3 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-sheet-1 {
    min-height: 976px;
  }

  .u-section-3 .u-layout-wrap-1 {
    margin-top: 31px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-3 .u-image-1 {
    height: 200px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-2 {
    margin-right: 0;
  }

  .u-section-3 .u-list-1 {
    width: 340px;
    margin-bottom: 31px;
  }

  .u-section-3 .u-text-3 {
    margin-right: 0;
  }
}.u-section-4 .u-sheet-1 {
  min-height: 694px;
}

.u-section-4 {
  background-color: #F5F5F5;
}

.u-section-4 .u-image-1 {
  width: 700px;
  height: 500px;
  object-position: 95.67% 50%;
  background-position: 95.67% 50%;
  margin: 55px 0 0 auto;
}

.u-section-4 .u-group-1 {
  width: 508px;
  min-height: 485px;
  margin: -40% auto 97px 0;
}

.u-section-4 .u-container-layout-1 {
  padding: 30px 60px 30px 0;
}

.u-section-4 .u-text-1 {
  font-weight: 700;
  line-height: 52.8px;
  letter-spacing: normal;
  font-family: Roboto, sans-serif;
  margin: 0;
}

.u-section-4 .u-text-2 {
  font-size: 1.25rem;
  margin: 30px 105px 0 0;
}

.u-section-4 .u-text-3 {
  margin: 20px 302px 0 0;
}

.u-section-4 .u-btn-1 {
  border-style: none none solid;
  padding: 0;
}

.u-section-4 .u-btn-2 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: solid;
  background-image: none;
  margin: 20px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-4 .u-sheet-1 {
    min-height: 695px;
  }

  .u-section-4 .u-image-1 {
    width: 692px;
    height: 577px;
  }

  .u-section-4 .u-group-1 {
    width: 419px;
    margin-top: -519px;
    margin-bottom: 60px;
  }

  .u-section-4 .u-text-2 {
    width: auto;
    margin-right: 63px;
  }

  .u-section-4 .u-text-3 {
    width: auto;
    margin-right: 185px;
  }

  .u-section-4 .u-btn-2 {
    padding: 10px 52px 10px 51px;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-sheet-1 {
    min-height: 857px;
  }

  .u-section-4 .u-image-1 {
    height: 447px;
    margin-top: 360px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-4 .u-group-1 {
    min-height: 325px;
    margin-top: -772px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-4 .u-container-layout-1 {
    padding: 0 30px;
  }

  .u-section-4 .u-text-1 {
    width: auto;
  }

  .u-section-4 .u-text-2 {
    margin-right: 0;
  }

  .u-section-4 .u-text-3 {
    width: 475px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-section-4 .u-btn-2 {
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-sheet-1 {
    min-height: 807px;
  }

  .u-section-4 .u-image-1 {
    height: 393px;
    width: 600px;
    margin-top: 380px;
    margin-left: -30px;
    margin-right: -30px;
  }

  .u-section-4 .u-group-1 {
    margin-top: -718px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-4 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-text-3 {
    margin-right: 185px;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-sheet-1 {
    min-height: 757px;
  }

  .u-section-4 .u-image-1 {
    height: 299px;
    width: 416px;
    margin-top: 403px;
    margin-left: -38px;
    margin-right: -38px;
  }

  .u-section-4 .u-group-1 {
    margin-top: -647px;
    margin-bottom: -191px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-4 .u-text-3 {
    margin-right: 134px;
    width: 320px;
  }
}.u-section-5 {
  background-image: none;
}

.u-section-5 .u-sheet-1 {
  min-height: 1089px;
}

.u-section-5 .u-image-1 {
  width: 648px;
  min-height: 969px;
  background-image: url("images/a7d25ea5-f10e-d3df-2733-55a779ff3988.png");
  background-position: 50% 50%;
  height: auto;
  margin: 49px 56px 0 auto;
}

.u-section-5 .u-container-layout-1 {
  padding: 30px;
}

.u-section-5 .u-image-2 {
  width: 959px;
  height: 723px;
  margin: -890px auto 0 0;
}

.u-section-5 .u-list-1 {
  min-height: 399px;
  grid-template-rows: auto;
  width: 524px;
  margin: -293px 0 60px auto;
}

.u-section-5 .u-repeater-1 {
  grid-auto-columns: calc(50% - 15.5px);
  grid-template-columns: calc(50% - 15.5px) calc(50% - 15.5px);
  grid-gap: 31px 31px;
  grid-auto-rows: calc(50% - 15.5px);
}

.u-section-5 .u-list-item-1 {
  background-image: none;
}

.u-section-5 .u-container-layout-2 {
  padding: 30px;
}

.u-section-5 .u-icon-1 {
  height: 74px;
  width: 74px;
  background-image: none;
  color: rgb(255, 255, 255) !important;
  margin: 0 auto;
}

.u-section-5 .u-text-1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  margin: 23px 0 0;
}

.u-section-5 .u-list-item-2 {
  background-image: none;
}

.u-section-5 .u-container-layout-3 {
  padding: 30px;
}

.u-section-5 .u-icon-2 {
  height: 74px;
  width: 74px;
  background-image: none;
  margin: 0 auto;
}

.u-section-5 .u-text-2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  margin: 23px 0 0;
}

.u-section-5 .u-list-item-3 {
  background-image: none;
}

.u-section-5 .u-container-layout-4 {
  padding: 30px;
}

.u-section-5 .u-icon-3 {
  height: 74px;
  width: 74px;
  background-image: none;
  margin: 0 auto;
}

.u-section-5 .u-text-3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  margin: 23px 0 0;
}

.u-section-5 .u-list-item-4 {
  background-image: none;
}

.u-section-5 .u-container-layout-5 {
  padding: 30px;
}

.u-section-5 .u-icon-4 {
  height: 74px;
  width: 74px;
  background-image: none;
  margin: 0 auto;
}

.u-section-5 .u-text-4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  margin: 23px 0 0;
}

@media (max-width: 1199px) {
  .u-section-5 .u-image-1 {
    width: 534px;
    height: auto;
  }

  .u-section-5 .u-container-layout-1 {
    padding-bottom: 0;
  }

  .u-section-5 .u-image-2 {
    width: 775px;
    height: 709px;
  }

  .u-section-5 .u-list-1 {
    min-height: 183px;
    height: auto;
  }

  .u-section-5 .u-repeater-1 {
    grid-auto-columns: calc(50% - 16.5px);
    grid-template-columns: calc(50% - 16.5px) calc(50% - 16.5px);
    grid-gap: 33px 33px;
    grid-auto-rows: calc(50% - 16.5px);
  }
}

@media (max-width: 991px) {
  .u-section-5 .u-sheet-1 {
    min-height: 1143px;
  }

  .u-section-5 .u-image-1 {
    width: 632px;
    min-height: 950px;
    margin-top: 28px;
    margin-right: 0;
  }

  .u-section-5 .u-container-layout-1 {
    padding-bottom: 45px;
  }

  .u-section-5 .u-image-2 {
    width: 664px;
    height: 664px;
    margin-top: -889px;
  }

  .u-section-5 .u-list-1 {
    margin-top: -298px;
  }
}

@media (max-width: 767px) {
  .u-section-5 .u-sheet-1 {
    min-height: 1062px;
  }

  .u-section-5 .u-image-1 {
    min-height: 890px;
    width: 483px;
    margin-top: 51px;
  }

  .u-section-5 .u-container-layout-1 {
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-image-2 {
    width: 478px;
    height: 478px;
    margin-top: -830px;
  }

  .u-section-5 .u-list-1 {
    min-height: 395px;
    width: 516px;
    margin-top: -136px;
    margin-bottom: 213px;
  }

  .u-section-5 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-5 .u-sheet-1 {
    min-height: 1319px;
  }

  .u-section-5 .u-image-1 {
    width: 309px;
    min-height: 652px;
    margin-top: 49px;
    margin-right: auto;
    margin-left: 81px;
  }

  .u-section-5 .u-image-2 {
    width: 340px;
    height: 340px;
    margin-top: -616px;
  }

  .u-section-5 .u-list-1 {
    min-height: 823px;
    width: 300px;
    margin-top: -52px;
    margin-bottom: 130px;
  }

  .u-section-5 .u-repeater-1 {
    grid-auto-columns: 100%;
    grid-template-columns: 100%;
    grid-auto-rows: calc(25% - 24.75px);
  }
}.u-section-6 .u-sheet-1 {
  min-height: 690px;
}

.u-section-6 .u-image-1 {
  width: 793px;
  height: 584px;
  object-position: 95.67% 50%;
  background-position: 95.67% 50%;
  margin: 53px auto 0 0;
}

.u-section-6 .u-group-1 {
  width: 508px;
  min-height: 485px;
  margin: -525px 0 93px auto;
}

.u-section-6 .u-container-layout-1 {
  padding: 30px 60px 30px 0;
}

.u-section-6 .u-text-1 {
  font-weight: 700;
  line-height: 52.8px;
  letter-spacing: normal;
  font-family: Roboto, sans-serif;
  margin: 0;
}

.u-section-6 .u-text-2 {
  font-size: 1.25rem;
  margin: 30px 0 0 105px;
}

.u-section-6 .u-text-3 {
  margin: 20px 0 0 302px;
}

.u-section-6 .u-btn-1 {
  border-style: none none solid;
  padding: 0;
}

.u-section-6 .u-btn-2 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: solid;
  background-image: none;
  margin: 20px 0 0 auto;
}

@media (max-width: 1199px) {
  .u-section-6 .u-sheet-1 {
    min-height: 650px;
  }

  .u-section-6 .u-image-1 {
    width: 587px;
    height: 544px;
  }

  .u-section-6 .u-group-1 {
    margin-top: -485px;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .u-section-6 .u-sheet-1 {
    min-height: 1029px;
  }

  .u-section-6 .u-image-1 {
    height: 485px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-group-1 {
    min-height: 418px;
    margin-top: 20px;
    margin-bottom: 53px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-container-layout-1 {
    padding-right: 30px;
  }

  .u-section-6 .u-text-1 {
    width: auto;
  }

  .u-section-6 .u-text-2 {
    width: auto;
    margin-left: 53px;
    margin-right: 53px;
  }

  .u-section-6 .u-text-3 {
    width: 176px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-section-6 .u-btn-2 {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .u-section-6 .u-sheet-1 {
    min-height: 890px;
  }

  .u-section-6 .u-image-1 {
    height: 346px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-container-layout-1 {
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-6 .u-sheet-1 {
    min-height: 741px;
  }

  .u-section-6 .u-image-1 {
    height: 234px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-group-1 {
    min-height: 390px;
    margin-top: 11px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-6 .u-text-3 {
    margin-left: 134px;
  }
}.u-section-7 {
  background-image: none;
}

.u-section-7 .u-sheet-1 {
  min-height: 1120px;
}

.u-section-7 .u-shape-1 {
  background-image: none;
  height: 567px;
  width: 784px;
  margin: 186px 0 0 auto;
}

.u-section-7 .u-image-1 {
  width: 560px;
  min-height: 615px;
  background-image: url("images/a7d25ea5-f10e-d3df-2733-55a779ff3988.png");
  background-position: 50% 50%;
  margin: -318px auto 0 43px;
}

.u-section-7 .u-container-layout-1 {
  padding: 30px;
}

.u-section-7 .u-image-2 {
  height: 627px;
  width: 856px;
  margin: -990px 72px 0 auto;
}

.u-section-7 .u-group-2 {
  width: 536px;
  min-height: 480px;
  margin: -197px auto 60px 0;
}

.u-section-7 .u-container-layout-2 {
  padding: 40px 60px;
}

.u-section-7 .u-text-1 {
  font-size: 3.75rem;
  font-family: Barlow;
  font-weight: 700;
  margin: 0;
}

.u-section-7 .u-text-2 {
  font-style: italic;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 20px 0 0;
}

.u-section-7 .u-text-3 {
  margin: 20px 7px 0 0;
}

.u-section-7 .u-btn-1 {
  border-style: none none solid;
  padding: 0;
}

.u-section-7 .u-btn-2 {
  background-image: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: none;
  font-weight: 700;
  margin: 20px auto 0 0;
  padding: 10px 52px 10px 51px;
}

@media (max-width: 1199px) {
  .u-section-7 .u-image-1 {
    width: 531px;
    min-height: 614px;
    margin-left: 72px;
  }

  .u-section-7 .u-image-2 {
    height: 559px;
    width: 727px;
    margin-top: -989px;
  }

  .u-section-7 .u-group-2 {
    margin-top: -129px;
  }
}

@media (max-width: 991px) {
  .u-section-7 .u-sheet-1 {
    min-height: 1115px;
  }

  .u-section-7 .u-shape-1 {
    height: 479px;
    width: 582px;
    margin-top: 193px;
  }

  .u-section-7 .u-image-1 {
    margin-top: -237px;
  }

  .u-section-7 .u-image-2 {
    height: 530px;
    width: 606px;
    margin-right: 48px;
  }

  .u-section-7 .u-group-2 {
    margin-top: -100px;
  }

  .u-section-7 .u-container-layout-2 {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .u-section-7 .u-sheet-1 {
    min-height: 1107px;
  }

  .u-section-7 .u-shape-1 {
    height: 378px;
    width: 454px;
    margin-top: 60px;
  }

  .u-section-7 .u-image-1 {
    width: 505px;
    min-height: 545px;
    margin-top: -136px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-7 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-2 {
    height: 455px;
    width: 476px;
    margin-top: -980px;
    margin-right: auto;
    margin-left: 15px;
  }

  .u-section-7 .u-group-2 {
    width: 480px;
    min-height: 413px;
    margin-top: 35px;
    margin-bottom: -56px;
  }
}

@media (max-width: 575px) {
  .u-section-7 .u-sheet-1 {
    min-height: 961px;
  }

  .u-section-7 .u-shape-1 {
    height: 257px;
    width: 305px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: 35px;
  }

  .u-section-7 .u-image-1 {
    width: 305px;
    min-height: 426px;
    margin-top: -15px;
    margin-right: auto;
    margin-left: 35px;
  }

  .u-section-7 .u-image-2 {
    height: 310px;
    width: 305px;
    margin-top: -861px;
    margin-left: 0;
  }

  .u-section-7 .u-group-2 {
    width: 305px;
    min-height: 446px;
    margin-top: 50px;
    margin-bottom: -88px;
  }

  .u-section-7 .u-container-layout-2 {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .u-section-7 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-7 .u-text-3 {
    margin-right: 0;
  }
}.u-section-8 {
  background-image: none;
}

.u-section-8 .u-sheet-1 {
  min-height: 475px;
}

.u-section-8 .u-layout-wrap-1 {
  margin: 60px auto 60px 0;
}

.u-section-8 .u-layout-cell-1 {
  min-height: 355px;
}

.u-section-8 .u-container-layout-1 {
  padding: 30px;
}

.u-section-8 .u-text-1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  background-image: none;
}

.u-section-8 .u-line-1 {
  width: 100px;
  height: 3px;
  margin: 30px auto 0 0;
}

.u-section-8 .u-layout-cell-2 {
  min-height: 355px;
}

.u-section-8 .u-container-layout-2 {
  padding: 30px;
}

.u-section-8 .u-text-2 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-8 .u-btn-1 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  border-style: solid;
  background-image: none;
  margin: 20px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-8 .u-sheet-1 {
    min-height: 412px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 293px;
  }

  .u-section-8 .u-text-1 {
    font-size: 3rem;
  }

  .u-section-8 .u-layout-cell-2 {
    min-height: 293px;
  }
}

@media (max-width: 991px) {
  .u-section-8 .u-sheet-1 {
    min-height: 316px;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 224px;
  }

  .u-section-8 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-8 .u-layout-cell-2 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .u-section-8 .u-sheet-1 {
    min-height: 237px;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 154px;
  }

  .u-section-8 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-8 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-8 .u-sheet-1 {
    min-height: 149px;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 212px;
  }
}.u-section-9 {
  background-image: url('https://www.teahub.io/photos/full/307-3077159_jungle-wallpaper-a1-tropical-dark-jungle-background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.u-section-9 .u-sheet-1 {
  min-height: 615px;
}

.u-section-9 .u-text-1 {
  width: 489px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  margin: 60px auto 0;
}

.u-section-9 .u-text-2 {
  font-size: 1.125rem;
  width: 628px;
  margin: 20px auto 0;
}

.u-section-9 .u-layout-wrap-1 {
  width: 1140px;
  margin: 44px auto 60px;
}

.u-section-9 .u-layout-cell-1 {
  min-height: 322px;
}

.u-section-9 .u-container-layout-1 {
  padding: 30px 20px;
}

.u-section-9 .u-icon-1 {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

.u-section-9 .u-text-3 {
  font-weight: 700;
  text-transform: none;
  font-size: 1.25rem;
  margin: 30px -1px 0;
}

.u-section-9 .u-text-4 {
  margin: 20px -1px 0;
}

.u-section-9 .u-layout-cell-2 {
  min-height: 322px;
}

.u-section-9 .u-container-layout-2 {
  padding: 30px 20px;
}

.u-section-9 .u-icon-2 {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

.u-section-9 .u-text-5 {
  font-weight: 700;
  text-transform: none;
  font-size: 1.25rem;
  margin: 30px -1px 0;
}

.u-section-9 .u-btn-1 {
  border-style: none;
  background-image: none;
  text-decoration: underline !important;
  align-self: center;
  margin: 20px auto 0;
  padding: 0;
}

.u-section-9 .u-btn-2 {
  border-style: none;
  background-image: none;
  text-decoration: underline !important;
  margin: 0 auto;
  padding: 0;
}

.u-section-9 .u-layout-cell-3 {
  min-height: 322px;
}

.u-section-9 .u-container-layout-3 {
  padding: 30px 20px;
}

.u-section-9 .u-icon-3 {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

.u-section-9 .u-text-6 {
  font-weight: 700;
  text-transform: none;
  font-size: 1.25rem;
  margin: 30px -1px 0;
}

.u-section-9 .u-text-7 {
  margin: 20px -1px 0;
}

@media (max-width: 1199px) {
  .u-section-9 .u-sheet-1 {
    min-height: 559px;
  }

  .u-section-9 .u-layout-wrap-1 {
    width: 940px;
  }

  .u-section-9 .u-layout-cell-1 {
    min-height: 266px;
  }

  .u-section-9 .u-text-3 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-9 .u-text-4 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-9 .u-layout-cell-2 {
    min-height: 266px;
  }

  .u-section-9 .u-text-5 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-9 .u-layout-cell-3 {
    min-height: 266px;
  }

  .u-section-9 .u-text-6 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-9 .u-text-7 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-9 .u-sheet-1 {
    min-height: 393px;
  }

  .u-section-9 .u-layout-wrap-1 {
    width: 720px;
  }

  .u-section-9 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-9 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-9 .u-layout-cell-3 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .u-section-9 .u-sheet-1 {
    min-height: 1153px;
  }

  .u-section-9 .u-text-1 {
    margin-left: 26px;
    margin-right: 26px;
  }

  .u-section-9 .u-text-2 {
    width: 540px;
  }

  .u-section-9 .u-layout-wrap-1 {
    width: 540px;
  }

  .u-section-9 .u-container-layout-1 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .u-section-9 .u-container-layout-2 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .u-section-9 .u-container-layout-3 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 575px) {
  .u-section-9 .u-text-1 {
    width: 340px;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-9 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
    width: 340px;
  }

  .u-section-9 .u-layout-wrap-1 {
    width: 340px;
    margin-left: 0;
    margin-right: 0;
  }
}